import React from 'react';
import { ProGallery } from 'pro-gallery/dist/es/src/index';
import experiments from '@wix/photography-client-lib/dist/src/sdk/experimentsWrapper';
import window from '@wix/photography-client-lib/dist/src/sdk/windowWrapper';
import '../../../styles/index';
import { utils } from '../../../utils';
import { parseStyleParams } from '@wix/photography-client-lib/dist/src/fullscreen/parseStyleParams.js';
import { resizeMediaUrl } from '@wix/photography-client-lib/dist/src/item/itemResizer';
import EventHandler from '../../../common/helpers/eventHandler';
import SiteHelper from '../../../common/helpers/siteHelper';
import FullscreenHelper from '../../../common/helpers/fullscreenHelper';
import LogHelper from '../../../common/helpers/logHelper';
import { PRO_GALLERY } from '../../../constants';
import ItemsHelper from '../../../common/helpers/itemsHelper';
import AccessibilityHelper from '../../../common/helpers/accessibilityHelper';
import ProFullscreenWrapper from '../FullscreenWrapper/FullscreenWrapper';

const PgContext = React.createContext({});

export default class ProGallerySantaWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullscreen: {
        clickedIdx: props.clickedIdx >= 0 ? props.clickedIdx : -1,
        fullscreenAnimating: false,
        directFullscreenItem:
          !window.firstProGalleryRender && props.directFullscreenItem,
      },
      isAccessible: false,
      itemsLoveData: {},
      container: props.dimensions,
    };
    this.FullscreenElement = ProFullscreenWrapper;

    this.siteHelper = new SiteHelper(this, props, false);
    this.logHelper = new LogHelper(this, props, PRO_GALLERY.SENTRY_DSN, false);
    this.itemsHelper = new ItemsHelper(this, props);
    this.fullscreenHelper = new FullscreenHelper(this, props, false);
    this.accessibilityHelper = new AccessibilityHelper(this, props);
    this.eventHandler = new EventHandler(this, props);

    this.onNewProps(props);
    window.firstProGalleryRender = true;
  }

  measureContainerIfNeeded() {
    const container = this.siteHelper.measureContainerIfNeeded();

    if (JSON.stringify(container) !== JSON.stringify(this.state.container)) {
      this.setState({ container });
    }
  }

  componentDidMount() {
    this.eventHandler.initComponentDidLayout();
    this.siteHelper.loadUserFonts();
    this.itemsHelper.initItemActions();
    this.accessibilityHelper.initAccessibility();

    this.onNewProps(this.props);
  }

  componentWillReceiveProps(props) {
    this.onNewProps(props);
  }

  componentWillUnmount() {
    this.accessibilityHelper.cleanupAccessibility();
  }

  onNewProps(props) {
    this.siteHelper.update(props);
    this.fullscreenHelper.update(props);
    this.logHelper.update(props);
    this.itemsHelper.update(props);
    this.accessibilityHelper.update(props);
    this.measureContainerIfNeeded();
  }

  render() {
    const { queryParams, notInView } = this.props;

    if (
      window.isSSR &&
      ((experiments && experiments('specs.pro-gallery.skipSsr') === 'true') ||
        (queryParams && queryParams.skipPgSsr === 'true'))
    ) {
      console.error('Skipping Pro Gallery SSR!', this.props);
      return <div />;
    }

    if (utils.isSSR() && notInView) {
      if (utils.isVerbose()) {
        console.log('PG not in view, skipping');
      }
      return <div id="pg-not-in-view" />;
    }

    if (utils.isVerbose()) {
      console.log('Pro Gallery wrapper!', this.props);
      console.count('[OOISSR] proGallery ooi wrapper render');
    }
    const { id, galleryId, style, forceHover, viewMode } = this.props;

    const isMobile = this.siteHelper.isMobile();

    const { styleParams } = style;
    styleParams.booleans
      ? (styleParams.booleans.isAccessible = this.state.isAccessible)
      : (styleParams.isAccessible = this.state.isAccessible);
    this.pgProps = {
      domId: id,
      galleryId,
      allowSSR: true,
      container: this.state.container,
      forceHover,
      noFollowForSEO: !this.siteHelper.isPremiumSite(),
      viewMode: this.siteHelper.parseViewMode(viewMode),
      scrollingElement: this.siteHelper.getScrollingElement(),
      itemsLoveData: this.state.itemsLoveData,
      resizeMediaUrl,
    };
    if (this.itemsHelper.pgItemsProps().length === 0) {
      this.eventHandler.setZeroItems();
    }
    const pgContextValue = { isMobile };
    const dom = [
      <PgContext.Provider key="provider" value={pgContextValue}>
        <ProGallery
          key="pro-gallery"
          ref={node => (this.node = node)}
          styles={this.siteHelper.getPGStyles()}
          eventsListener={this.eventHandler.handleEvent}
          {...this.pgProps}
          {...this.itemsHelper.pgItemsProps()}
        />
      </PgContext.Provider>,
    ];
    dom.push(
      <ProFullscreenWrapper
        {...this.pgProps}
        {...this.fullscreenHelper.fullscreenItemsProps()}
        key="pro-fullscreen"
        styleParams={parseStyleParams(styleParams, false, isMobile)}
        scrollTo={this.props.scrollTo}
        fullscreenAnimating={this.state.fullscreen.fullscreenAnimating}
        fullscreenIdx={
          this.state.fullscreen.directFullscreenItem &&
          this.state.fullscreen.directFullscreenItem.itemId
            ? 0
            : this.state.fullscreen.clickedIdx
        }
        eventsListener={this.eventHandler.handleFullscreenEvent}
      />,
    );

    return (
      <div id={`gallery-wrapper-${id}`} key={`gallery-wrapper-${id}`}>
        {dom}
      </div>
    );
  }
}
